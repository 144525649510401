@tailwind utilities;

@layer utilities {
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE, Edge */
    scrollbar-width: none; /* Firefox */
    overflow-x: auto;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  .custom-scrollbar {
    scrollbar-width: thin; /* Firefox: thin scrollbar */
    scrollbar-color: theme('colors.bg.inverseSecondary') transparent; /* Firefox: colors */
    overflow: auto; /* Ensure scrolling */
  }

  /* WebKit 기반 브라우저 (Chrome, Safari, Edge 등) */
  .custom-scrollbar::-webkit-scrollbar {
    width: 16px; /* 전체 스크롤바 너비 */
    background-color: transparent; /* 트랙 색상 */
    border-radius: theme('borderRadius.s');
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    width: 8px; /* Thumb 너비 */
    background-color: theme('colors.bg.inverseSecondary'); /* Thumb 색상 */
    border-radius: theme('borderRadius.s'); /* Thumb 테두리 */
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    width: 16px; /* 트랙 너비 */
    background-color: transparent; /* 트랙 색상 */
  }
}
